@font-face {
  font-family: Carnevalee;
  src: url('./assets/fonts/CarnevaleeFreakshow.ttf');
}

@font-face {
  font-family: Durango;
  src: url('./assets/fonts/DurangoWesternErodedDemo.otf');
}

@font-face {
  font-family: Showboat;
  src: url('./assets/fonts/Showboat.ttf');
}

@font-face {
  font-family: 'Quentin Caps';
  src: url('./assets/fonts/QUENCRG_.TTF');
}

@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

body {
  margin: 0;
      background-color: white;
        background-image: url('./assets/images/texture-old-faded-vintage-paper-beige-retro-background-grunge-paper-with-spots-streaks_213524-157.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
